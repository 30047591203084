body {
  margin: 0;
  font-family: "Adelle";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1 {
  font-family: "Adelle";
  color: #6e5b46;
  text-align: center;
}
h3,
h4,
h5,
h6 {
  font-family: "Adelle";
}
hr {
  background-color: #c8db2a;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@font-face {
  font-family: "Adelle";
  src: local("Adelle"),
    url("../src/assests/Fonts/Adelle_Regular.otf") format("opentype");
}
