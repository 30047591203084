.header_text {
  text-align: center;
}
.btn.update {
  background-color: #ffd006;
}
.btn.btn-danger {
  float: right;
}

.tissue_scores {
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.tissue_score {
  font-weight: bold;
  /* margin-left: 100px; */
}
