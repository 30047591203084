.processor_link_div {
  outline: outset #c8db2a;
  outline-offset: 3px;
  margin-top: 5%;
  color: rgba(0, 0, 0, 0.87);
}
.agency_processor_link_div {
  outline: outset #c8db2a;
  outline-offset: 2px;
  margin-bottom: 3%;
  padding: 1%;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
}

.processor_drop_down {
  height: 43vh;
}
.view_by_agency {
  color: #015239;
  text-align: right;
  font-size: 13px;
  margin-bottom: 0%;
}
/* agencies button */
.btn.btn-outline-light {
  float: right;
  background-color: #008b8b;
}

.agency_header {
  margin-top: 3%;
}

.hide {
  font-size: 14px;
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.header_text {
  text-align: center;
}

.btn.update.edit_processor {
  background-color: #ffd006;
  margin-top: 3%;
}

.btn.btn-danger.edit_processor {
  margin-top: 3%;
}

.page_description {
  color: #015239;
  text-align: center;
}
.agency_outline {
  outline: outset#c8db2a;
  outline-offset: 5px;
  margin: 1%;
}

.btn.update.priority_order {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-bottom: 2%;
  background-color: #ffd006;
}

.MuiFormControlLabel-asterisk {
  display: none;
}
