.header {
  text-align: center;
  margin-top: 17px;
}

.btn.header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  background-color: #c8db2a;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  color: #212529;
  display: inline-block;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 0.375rem 0.75rem;
  text-align: center;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  vertical-align: middle;
}

.hospital_detail {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 60px;
}

.btn.update {
  background-color: #ffd006;
}
.btn.submit {
  background-color: #ffd006;
}

.btn.btn-danger {
  float: right;
}
