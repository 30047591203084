.imgSize {
  display: block;
  margin: auto;
  width: 45%;
}

@media screen and (max-width: 600px) {
  .imgSize {
    background-color: #d9e56958;
    display: block;
    margin: auto;
    width: 100%;
  }
}

.MuiFormControlLabel-asterisk {
  display: none;
}
